<template>
    <v-layout pa-2 class="question" mt-5 wrap v-if="enums">
        <v-flex xs12 sm8>{{ vraag.omschrijving }}</v-flex>
        <v-flex xs12 pl-3 sm4>
            <v-select :rules="rules" v-model="selected" :items="enums"></v-select>
        </v-flex>
        <v-flex v-if="showTextArea()" xs12>
            <v-textarea :rules="rules" v-model="valueText" filled>
            </v-textarea>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    props: { vraag: Object, 
        rules: {
            type: Array,
            required: false
        }, enums :{
            type: Array,
            required: false
        } 
    },
    data: () => ({
        selected: null,
        valueText: "",
        expandOn: ["Anders, te weten:"]
    }),
    methods: {
        showTextArea() {
            if (this.selected != null) {
                return this.expandOn.includes(this.selected)
            }
            return false
        }
    },
    computed: {
        combined() {
            if (this.showTextArea()) {
                return this.valueText
            }
            return this.selected
        }
    },
    watch: {
        combined: {
            immediate: true,
            handler: function (c) {
                if (c) {
                    this.vraag.reactie = c
                }
            },
        },
    },
    created() {

        if (this.vraag.reactie != null) {

            const found = this.enums.find(f => f.text === this.vraag.reactie)

            if (found) {
                this.selected = this.vraag.reactie
            } else {

                const intersection = this.expandOn.filter(element => this.enums.map(s => s.text).includes(element));



                if (intersection.length > 0)
                    this.selected = intersection[0]


                this.valueText = this.vraag.reactie
            }
        }



    }
}
</script>